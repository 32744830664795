import { defineStore } from 'pinia';

export const useConfigStore = defineStore('config', {
  state: () => ({
    envConfig: {
      development: "qaapi",
      sandbox: "sandboxapi",
      production: "api"
    },
    domain: "legendtrading.com",
    env: process.env.NODE_ENV,
    mode: "",
    extraConfig: {
      development: { checkout: "pk_sbox_krbmse35h3a6njiilb7rpigdteu" },
      sandbox: { checkout: "pk_sbox_krbmse35h3a6njiilb7rpigdteu" },
      production: { checkout: "pk_2fewwrbxyyvlq4btvxm63fkm4er" }
    }
  }),
  getters: {
    baseUrl: (state) => {
      const { envConfig, domain, env } = state;
      if (envConfig.development === "mock") {
        return `https://devapi.${domain}`;
      } else {
        return `https://${envConfig[env]}.${domain}`;
      }
    },
    baseUrlTrade() {
      return `${this.baseUrl}/v3/ex/`;
    },
    baseUrlKyc() {
      return `${this.baseUrl}/v3/kyc/`;
    },
    baseUrlPlaid() {
      return `${this.baseUrl}/v3/plaid/`;
    },
    baseRemote: (state) => {
      return state.envConfig.development;
    },
    checkoutKey: (state) => {
      const { extraConfig, env } = state;
      return extraConfig[env].checkout;
    },
  },
  actions: {
    updateConfigMode(mode) {
      this.mode = mode;
    },
    updateConfigEnv(env) {
      this.env = env;
    },
    updateConfigRemote(remote) {
      this.envConfig.development = remote;
    },
    updateConfigExtra(value) {
      this.extraConfig = {
        ...this.extraConfig,
        [this.env]: {
          ...this.extraConfig[env],
          ...value
        }
      };
    },
  }
});
