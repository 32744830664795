import { defineStore } from 'pinia';

export const usePaymentStore = defineStore('payment', {
  state: () => ({
    creditCardInfo: {},
    cardAddressId: "",
  }),
  getters: {},
  actions: {
    updateCreditCardInfo(data) {
      this.creditCardInfo = data;
    },
    updateCardAddressId(data) {
      this.cardAddressId = data;
    },
  }
});
