import { forEach } from 'lodash';
import { createApp, toRaw } from 'vue';
import { createPinia } from 'pinia';
import mitt from 'mitt';

import {
  createApi,
  sign,
  authorize,
  getPairs,
  getCurrencies,
  getBalance,
  queryRegion,
  queryProfile,
  saveBillingAddress,
  queryPaymentmethods,
  deletePaymentmethod,
  queryTradePaymentMethods,
  submitCreditcard,
  queryQuoteForFiat,
  submitOrderForFiat,
} from './api';
import { useBaseStore, useConfigStore, usePaymentStore } from './store';
import { getCurrencyUnitList } from './utils';
import { loadCheckoutFramesV2, loadCheckoutRisk } from "./utils/loadjs";

export const LegendBase = class {
  $app;
  $store;
  $api;
  $events;
  $sardine;
  $risk;

  constructor({ mode, remote, checkout } = {}) {
    this.#setupApp();
    this.#setupEvents();
    this.#setupEnv({ mode, remote, checkout });

    if (['dev', 'sandbox', 'demo', 'demo-2023'].includes(this.mode)) this.print();
  }

  destroy() {
    // TODO
    this.$app = null;
    this.$store = null;
    this.$api = null;
    this.$events.all.clear();
    this.$events = null;
    this.on = null;
    this.off = null;
    this.all = null;
  }

  print() {
    console.group("[legend-base constructor]");
    console.log("instance mode: \n%o", this.mode);
    console.log("instance baseStore: \n%o", toRaw(this.$store.base.$state));
    console.log("instance configStore: \n%o", toRaw(this.$store.config.$state));
    console.groupEnd("[legend-base constructor]");
  }

  get mode() {
    return this.$store.config.mode;
  }
  set mode(value) {
    this.$store.config.updateConfigMode(value);
  }

  get remote() {
    return this.$store.config.remote;
  }
  set remote(value) {
    this.$store.config.updateConfigRemote(value);
  }

  get checkout() {
    return this.$store.config.checkoutKey;
  }
  set checkout(checkout) {
    this.$store.config.updateConfigExtra({ checkout });
  }

  #setupEnv({ mode, remote, checkout }) {
    if (mode) this.mode = mode;
    if (remote) this.remote = remote;

    if (process.env.NODE_ENV === "development") {
      // respect env config during develpoment
    } else if (["dev", "demo-2023"].includes(this.mode)) {
      this.$store.config.updateConfigEnv("development");
    } else if (["sandbox", "demo"].includes(this.mode)) {
      this.$store.config.updateConfigEnv("sandbox");
    } else {
      this.$store.config.updateConfigEnv(process.env.NODE_ENV);
    }

    // setup checkout public key at last, as the env may be changed during setup
    if (checkout) this.checkout = checkout;
  }

  #setupEvents() {
    const events = mitt();
    this.$events = events;
    this.on = events.on;
    this.off = events.off;
    this.all = events.all;
  }

  #setupApp() {
    const app = createApp();
    const pinia = createPinia();
    app.use(pinia);

    const base = useBaseStore();
    const config = useConfigStore();
    const payment = usePaymentStore();

    this.$app = app;
    this.$store = { base, config, payment };
    this.$api = createApi.call(this);
  }

  async #loadFrames(options) {
    return loadCheckoutFramesV2().then(() => {
      window.Frames.init({
        publicKey: this.checkout,
        localization: {
          cardNumberPlaceholder: "Card number",
          expiryMonthPlaceholder: "MM",
          expiryYearPlaceholder: "YY",
          cvvPlaceholder: "CVC/CVV",
          ...options.localization,
        },
        cardNumber: {
          frameSelector: ".frame-card-number",
          ...options.cardNumber,
        },
        expiryDate: {
          frameSelector: ".frame-expiry-date",
          ...options.expiryDate,
        },
        cvv: {
          frameSelector: ".frame-cvv",
          ...options.cvv,
        },
        style: {
          base: {
            color: "#0a0a16",
            fontSize: "20px",
            fontFamily:
              "Poppins, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, monospace",
            letterSpacing: "normal",
            fontWeight: "normal",
            height: "54px",
          },
          placeholder: {
            base: {
              color: "#ceced0",
              fontWeight: "normal"
            }
          },
          ...options.style
        },
        ready: () => { }
      });

      forEach(options.events, (value, key) => {
        window.Frames.addEventHandler(
          window.Frames.Events[key],
          value
        );
      });
    });
  }

  async #loadSardine(options) {
    console.log("Skip loading of sardine, no longer using.");
    return this.$sardine;
  }

  async #loadCheckoutRisk() {
    if (!this.$risk) {
      await loadCheckoutRisk(this.mode);
      this.$risk = window.Risk.init(this.checkout);
    }

    return this.$risk;
  }

  async #publishCheckoutRiskData() {
    try {
      await this.#loadCheckoutRisk();

      const deviceSessionId = await this.$risk?.publishRiskData?.();
      // console.log("deviceSessionId: ", deviceSessionId);
      return deviceSessionId;
    } catch (err) {
      console.error(err);
      return "";
    }
  }

  async #handleRedirect(options) {
    // Checkout Prism
    // response.s tatus === 202
    const $el = document.querySelector(options.selector);
    if ($el.tagName === "IFRAME") {
      $el.src = options.url;
    } else {
      console.warn("Can not found IFRAME element, create one.");
      const reVoidElement = /^(?:area|base|br|col|embed|hr|img|input|link|meta|param|source|track|wbr)$/i
      if (reVoidElement.test($el.tagName)) {
        throw new Error("Got a void element for iframe container.");
      } else {
        if ($el.childNodes.length > 0) {
          console.warn("Container element is not empty.");
        }
        const $iframe = document.createElement("IFRAME");
        $iframe.src = options.url;
        $el.replaceChildren($iframe);
      }
    }
    this.$events.emit("legend:frame-3ds", { visible: true });
    options.showFrame3Ds?.();
    this.#bindMessageEvent();

    const data = await new Promise((resolve, reject) => {
      this.$events.on("legend:payment-success", (e) => {
        resolve(e.data);
      });
      this.$events.on("legend:payment-failed", reject);
      this.$events.on("legend:payment-pending", reject);
      this.$events.on("legend:payment-error", reject);
    });
    this.$events.emit("legend:frame-3ds", { visible: false });
    options.hideFrame3Ds?.();

    return data;
  }
  #handleMessage(event) {
    const reLegendTrading = /legendtrading\.com$/i;
    const TARGET_REDIRECT = "checkout-redirect";
    const { origin, data: response } = event;
    if (!reLegendTrading.test(origin)) {
      // message is not from LegendTrading origin
      return;
    } else if (response.target !== TARGET_REDIRECT) {
      // message target is not checkout-redirect
      return;
    } else {
      // any valid message from redirect
      if (response.status === "success") {
        this.$events.emit("legend:payment-success", response);
      } else if (response.status === "failed") {
        // show a specific modal for payment failed
        this.$events.emit("legend:payment-failed", response);
      } else if (response.status === "pending") {
        // show pending message and goto quotation
        this.$events.emit("legend:payment-pending", response);
      } else {
        this.$events.emit("legend:payment-error", response);
      }

      this.#unbindMessageEvent();
    }
  }
  #bindMessageEvent() {
    window.addEventListener("message", this.#handleMessage.bind(this), false);
  }
  #unbindMessageEvent() {
    window.removeEventListener("message", this.#handleMessage.bind(this), false);
  }

  sign = sign
  async authorize(options) {
    const resp = await authorize.call(this, { ...options, custom_config: 'pure_card' });
    const { user_preference, exchange_config } = resp;
    this.$store.base.updateState("kycStatus", resp.kyc_status);
    this.$store.base.updateState("nextKycStep", resp.next_kyc_step);
    this.$store.base.updateState("originalNextKycStep", resp.next_kyc_step === "redo_verify_id"
      ? next_kyc_step
      : "enter_email"
    );
    this.$store.base.updateState("connectStatus", resp.connect_status);
    this.$store.base.updateState("user_preference", user_preference ?? { default_fiat: null });
    this.$store.base.updateState("asset", user_preference?.default_fiat ?? "USD");
    this.$store.base.updateState("isHighRiskIp", user_preference?.ip_disabled ?? false);
    this.$store.base.updateState("ipAddressInfo", user_preference?.ip_address_info ?? {
      country: "",
      state: ""
    })
    this.$store.base.updateState("accountEmail", resp.account_email ?? "");
    this.$store.base.updateState("accountType", resp.account_type?.toLowerCase() ?? "individual");
    this.$store.base.updateState("token", resp.access_token);
    this.$store.base.updateState("refreshToken", resp.refresh_token);
    this.$store.base.updateState("expiresIn", resp.expires_in);
    this.$store.base.updateState("expiresAt", resp.expires_at);
    this.$store.base.updateState("tokenType", resp.token_type);
    this.$store.base.updateState("exchangeName", exchange_config?.exchange_name ?? "");
    this.$store.base.updateState("exchangeConfig", exchange_config);

    this.$events.emit("legend:kyc-connect", { status: resp.kyc_status });

    if (resp.token_type === "Bearer") {
      // get sardine session
      // const sardine = await this.#loadSardine({ flow: "authorize" });
      // console.log(sardine);

      // load checkout risk script
      // const risk = await this.#loadCheckoutRisk();
      // console.log(risk);
    }

    return resp;
  }

  getState() {
    return toRaw(this.$store.base.$state);
  }

  async getTradingPairs() {
    const pairs = await getPairs.call(this, { is_sort: true });
    this.$store.base.updateState("pairs", pairs);
    return pairs;
  }

  async getCurrencies() {
    const currencies = await getCurrencies.call(this);
    const currencyUnitList = getCurrencyUnitList(currencies);
    const { minimum_deposit = 0 } = currencies.find(
      item => item.currency === this.$store.base.asset
    );
    this.$store.base.updateState("minimumTradingSize", minimum_deposit);
    this.$store.base.updateState("currencies", currencies);
    this.$store.base.updateState("currencyUnitList", currencyUnitList);
    return currencies;
  }

  async getBalance() {
    const balance = await getBalance.call(this);
    this.$store.base.updateState("balance", balance);
    return balance;
  }

  async getRegions(options) {
    return await queryRegion.call(this, options);
  }

  async getBillingAddress() {
    return await queryProfile.call(this);
  }

  async setBillingAddress(options) {
    const resp = await saveBillingAddress.call(this, options);
    this.$store.payment.updateCardAddressId(resp.uuid);
    return resp;
  }

  async getAllPaymentMethods() {
    return await queryPaymentmethods.call(this);
  }

  async getPaymentMethodsByTrade(options) {
    return await queryTradePaymentMethods.call(this, options);
  }

  async addPaymentMethod(options) {
    // TOOD

    if (options.uuidAddress) {
      this.$store.payment.updateCardAddressId(options.uuidAddress);
    }

    await this.#loadFrames(options);

    const $submit = document.querySelector(options.btnSubmitSelector);
    $submit?.addEventListener("click", async () => {
      try {
        const resp1 = await window.Frames.submitCard()
        const params = {
          data: resp1,
          currency: "USD",
          billing_address: this.$store.payment.cardAddressId
        };
        this.$store.payment.updateCreditCardInfo(params);
        const resp2 = await submitCreditcard.call(this, params);
        if (resp2.redirect_url) {
          const resp3 = await this.#handleRedirect({
            url: resp2.redirect_url,
            selector: options.frame3DsSelector,
            showFrame3Ds: options.showFrame3Ds,
            hideFrame3Ds: options.hideFrame3Ds,
          });

          if (resp3) {
            this.$events.emit("legend:add-payment-method", {
              type: "CREDIT",
              entry: ""
            });
          }

          return resp3;
        } else {
          this.$events.emit("legend:payment-success", { data: resp2 });
          this.$events.emit("legend:add-payment-method", {
            type: "CREDIT",
            entry: ""
          });
          return resp2;
        }
      } catch (err) {
        window.Frames.enableSubmitForm();
        this.$events.emit("legend:payment-error", err?.response?.data);
        throw err;
      }
    });

    return new Promise((resolve, reject) => {
      this.$events.on("legend:add-payment-method", data => {
        resolve({ status: "success", data });
      });
      this.$events.on("legend:payment-failed", data => {
        reject({ status: "failed", data });
      });
      this.$events.on("legend:payment-pending", data => {
        reject({ status: "pending", data });
      });
      this.$events.on("legend:payment-error", data => {
        reject({ status: "error", data });
      });
    });
  }

  async removePaymentMethod(options) {
    return await deletePaymentmethod.call(this, options);
  }

  async quoteByFiat(options) {
    if (options.side === 'buy') {
      // get sardine session
      // const sardine = await this.#loadSardine({ flow: "quote" });
      // console.log(sardine);
    }

    return await queryQuoteForFiat.call(this, options);
  }

  async orderByFiat(options) {
    try {
      let device_session_id = '';
      if (options.side === 'buy') {
        // get sardine session
        const sardine = await this.#loadSardine({ flow: "payment" });
        // console.log(sardine);
        // get checkout deviceSessionId
        device_session_id = await this.#publishCheckoutRiskData();
      }

      const resp = await submitOrderForFiat.call(this, { ...options, device_session_id });
      if (resp.redirect_url) {
        const data = await this.#handleRedirect({
          url: resp.redirect_url,
          selector: options.frame3DsSelector,
          showFrame3Ds: options.showFrame3Ds,
          hideFrame3Ds: options.hideFrame3Ds,
        });

        return data;
      } else {
        this.$events.emit("legend:payment-success", { data: resp });
        return resp;
      }
    } catch (err) {
      throw err;
    }
  }

  async test(options) {
    const data = await this.#handleRedirect({
      url: options.redirect_url,
      selector: options.frame3DsSelector,
      showFrame3Ds: options.showFrame3Ds,
      hideFrame3Ds: options.hideFrame3Ds,
    });

    return data;
  }
};

export default LegendBase;
