import { defineStore } from 'pinia';
import { useConfigStore } from './config';
import { usePaymentStore } from './payment';

const useBaseStore = defineStore('base', {
  state: () => ({
    kycStatus: "Not Started", // Not Started, Started, In Progress, Submitted, In Review, Approved, Rejected
    nextKycStep: "enter_email",
    originalNextKycStep: "enter_email",
    connectStatus: "", // Pending
    accountEmail: "",
    accountEmailHash: "",
    accountType: "", // Individual, Entity
    expiresIn: 0,
    expiresAt: "",
    token: "",
    refreshToken: "",
    pairs: [],
    asset: "",
    balance: {
      crypto_exchange: {},
      crypto: {},
      fiat: {}
    },
    exchangeConfig: {
      auto_withdrawal: false,
      trade_confirmation_popup: "",
      minimum_trading_size: "50"
    },
    user_preference: {
      banner: null,
      default_fiat: null,
      skip_bank_disclaimer: false,
      skip_wire_disclaimer: false
    },
    currencies: [],
    minimumTradingSize: 0,
    exchangeName: "",
    operationType: "credit_card", // "deposit" | "credit_card" | ""
    tokenType: "", // Bearer | Kyc
    isHighRiskIp: false,
    ipAddressInfo: {
      country: "",
      state: ""
    },
    currencyUnitList: {},
    regionListType: "", // bank_list, credit_card_list
  }),
  getters: {
    baseUrl: () => {
      const config = useConfigStore();
      return config.baseUrl;
    },
  },
  actions: {
    updateState(key, status) {
      this[key] = status;
    }
  },
});

export { useBaseStore, useConfigStore, usePaymentStore };
