import axios from "axios";

export function createApi() {
  const api = axios.create({
    baseURL: this.$store.config.baseUrlTrade,
    headers: {
      Authorization: `Bearer`,
      Accept: "application/json",
    },
    timeout: 180000,
  });

  api.interceptors.request.use((config) => {
    config.baseURL = this.$store.config.baseUrlTrade;
    config.headers.Authorization = `Bearer ${this.$store.base.token}`;
    return config;
  });

  return api;
}

export async function sign({
  json = 1,
  uid = null,
  status = "In Progress",
  next_kyc_step = "verify_id",
  app_id = null,
  email = null,
  phone = null,
} = {}) {
  const resp = await this.$api.get("/sign", {
    params: {
      json,
      uid,
      status,
      next_kyc_step,
      app_id,
      email,
      phone,
    },
  });
  return resp.data;
}

export async function authorize({
  timestamp,
  signature,
  custom_config,
  appKey,
  appId,
  appUrl,
  appUid,
  appEmail,
  appPhone,
}) {
  const resp = await this.$api.post("/authorize", {
    timestamp,
    signature,
    custom_config,
    "APP-KEY": appKey,
    "APP-ID": appId,
    "APP-URL": appUrl,
    "APP-UID": appUid,
    "APP-EMAIL": appEmail,
    "APP-PHONE": appPhone,
  });
  return resp.data;
}

export async function getSardineConfig(params) {
  const resp = await this.$api.post("/risksession", params);
  return resp.data;
}

export async function getPairs({ is_sort = false } = {}) {
  const resp = await this.$api.get("/pairs", {
    params: { is_sort: is_sort ? 1 : 0 },
  });
  return resp;
}

export async function getCurrencies() {
  const resp = await this.$api.get("/currencies");
  return resp.data;
}

export async function getBalance() {
  const resp = await this.$api.get("/balance");
  return resp.data;
}

export async function queryRegion({ parent_id }) {
  const resp = await this.$api.get("/region", {
    params: { parent_id },
  });
  return resp.data;
}

export async function queryProfile() {
  const resp = await this.$api.get("/profile");
  return resp.data;
}

export async function submitProfile(params) {
  const resp = await this.$api.post("/profile", params);
  return resp.data;
}

export async function queryPaymentmethods() {
  const resp = await this.$api.get("/paymentmethods");
  return resp.data;
}

export async function deletePaymentmethod({ payment_method_id = "" }) {
  const resp = await this.$api.delete("/paymentmethod", {
    params: { payment_method_id },
  });
  return resp.data;
}

export async function queryTradePaymentMethods({ pair = "", side = "" }) {
  const resp = await this.$api.get("/order/paymentmethods", {
    params: { pair, side },
  });
  return resp.data;
}

export async function saveBillingAddress({
  country = "",
  state = "",
  city = "",
  address_line_1 = "",
  address_line_2 = "",
  zipcode,
}) {
  const resp = await this.$api.post("/billingaddress", {
    country,
    state,
    city,
    address_line_1,
    address_line_2,
    zipcode,
  });
  // throw new Error("yahaha");
  return resp.data;
}

export async function submitCreditcard(params) {
  const resp = await this.$api.post("/creditcard", params);
  return resp.data;
}

export async function queryQuoteForFiat(params) {
  const resp = await this.$api.post("/quotebyfiat", params);
  return resp.data;
}

export async function submitOrderForFiat(params) {
  const resp = await this.$api.post("/orderbyfiat", params);
  return resp.data;
}
