import loadjs from "loadjs";

const CONFIG = {
  checkoutFramesV2: {
    baseUrl: "https://cdn.checkout.com/js/framesv2.min.js"
  },
  checkoutRisk: {
    baseUrl: "https://risk.checkout.com/cdn/risk/1/risk.js"
  },
  sardine: {
    baseUrl: "https://api.sardine.ai/assets/loader.min.js"
  }
};

const CONFIG_SANDBOX = {
  checkoutRisk: {
    baseUrl: "https://risk.sandbox.checkout.com/cdn/risk/1/risk.js"
  },
  sardine: {
    baseUrl: "https://api.sandbox.sardine.ai/assets/loader.min.js"
  }
};

export const loadExternalLib = (lib, env = "") => {
  const isSandbox = ["dev", "sandbox", "demo", "demo-2023"].includes(env);
  const config = isSandbox ? CONFIG_SANDBOX : CONFIG;
  const { baseUrl, searchParams, loadOptions } = config[lib];
  const search = new URLSearchParams(searchParams).toString();

  return loadjs.isDefined(lib)
    ? Promise.resolve()
    : loadjs([`${baseUrl}?${search}`], lib, {
      returnPromise: true,
      ...loadOptions
    });
};

export const loadCheckoutFramesV2 = () => loadExternalLib("checkoutFramesV2");

export const loadCheckoutRisk = env => loadExternalLib("checkoutRisk", env);

export const loadSardine = env => loadExternalLib("sardine", env);
